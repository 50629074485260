

import React from 'react';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import LoadingButton from 'src/components/common/loading-button';
import ThemeLogo from 'src/components/common/theme-logo';
import UserAvatar from 'src/components/common/user-avatar';
import Link from 'src/components/router/link';
import { APP_REGISTER_URL, PREMIUM_TIERS } from 'src/constants';

const clsPrefix = 'app-account-dropdown-avatar';
const SubMenu = Menu.SubMenu;

const AppDropdownAvatar = inject(
  'ui',
  'store',
  'account'
)(
  observer(({ ui, store, account, showThemeLogo, className }) => {
    function handleOnClick(e) {
      const { key } = e;

      if (key === 'logout') {
        store.logOut();
      }
    }

    if (!account.isAuthenticated) {
      return null;
    }

    const appName = store.appName;
    const orgInfo = account && account.user && account.user.orgInfo;
    const canSeeOrgMenu = orgInfo && appName === 'agent' && orgInfo.canSeeOrg;
    const isPro = account.isProAccount;
    const isBrokerage = account.isBrokerageAccount;

    const canSeeBilling =
      isPro &&
      account.isUpgradeable &&
      (!isBrokerage || !PREMIUM_TIERS.includes(orgInfo.featureOverrides?.tier));

    const canSeeUpgrade = account.showUpgradePromo;

    const canSeeTransactionTemplates =
      account.getUserFeatures().templates && account.isAgentApp;

    const canSeeClauseManager = account.isTmsEnabled && account.isAgentApp;

    const menu = (
      <Menu onClick={handleOnClick} selectable={false}>
        {appName === 'agent' || appName === 'client' ? (
          <Menu.Item key="account" className="test_account_setting">
            <Link routeName="account">Account Settings</Link>
          </Menu.Item>
        ) : null}
        {canSeeOrgMenu ? (
          <SubMenu key="admin" title="Administration" className="test-org">
            {orgInfo.canSeeOrg ? (
              <Menu.Item className="test-members" key="members">
                <Link
                  routeName="orgs.members"
                  routeParams={{
                    orgId: orgInfo.orgId,
                  }}
                >
                  Members
                </Link>
              </Menu.Item>
            ) : null}
            {orgInfo.canSeeGroups ? (
              <Menu.Item className="test-groups" key="groups">
                <Link
                  routeName="orgs.groups"
                  routeParams={{
                    orgId: orgInfo.orgId,
                  }}
                >
                  Groups
                </Link>
              </Menu.Item>
            ) : null}
            {orgInfo.canSeeOffices ? (
              <Menu.Item className="test-offices" key="offices">
                <Link
                  routeName="orgs.offices"
                  routeParams={{
                    orgId: orgInfo.orgId,
                  }}
                >
                  Offices
                </Link>
              </Menu.Item>
            ) : null}
            {orgInfo.canSeeOrg && orgInfo.featureOverrides?.checklists ? (
              <Menu.Item className="test-checklists" key="checklists">
                <Link
                  routeName="orgs.checklists"
                  routeParams={{
                    orgId: orgInfo.orgId,
                  }}
                >
                  Checklists
                </Link>
              </Menu.Item>
            ) : null}
            {orgInfo.canSeeOrg ? (
              <Menu.Item className="test-settings" key="settings">
                <Link
                  routeName="orgs.settings"
                  routeParams={{
                    orgId: orgInfo.orgId,
                  }}
                >
                  Settings
                </Link>
              </Menu.Item>
            ) : null}
          </SubMenu>
        ) : null}
        {canSeeTransactionTemplates && (
          <Menu.Item
            className={`${clsPrefix}__transaction-templates`}
            key="templates"
          >
            <Link routeName="transactionTemplates">Transaction Templates</Link>
          </Menu.Item>
        )}
        {canSeeClauseManager && (
          <Menu.Item className={`${clsPrefix}__clauses`} key="clauses">
            <Link routeName="clauses">Clause Manager</Link>
          </Menu.Item>
        )}
        {canSeeUpgrade && (
          <Menu.Item key="upgrade" className="test_account_upgrade">
            <Link routeName="upgrade">Upgrade to Pro</Link>
          </Menu.Item>
        )}
        {canSeeBilling && (
          <Menu.Item key="billing" className="test_account_billing">
            <Link routeName="billing">Billing</Link>
          </Menu.Item>
        )}
        <Menu.Item key="support" className="show-on-sm">
          <a
            href="mailto:support@glide.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@glide.com
          </a>
        </Menu.Item>
        <Menu.Item
          className={`${clsPrefix}__logout-button logout-button`}
          key="logout"
        >
          Sign Out
        </Menu.Item>
      </Menu>
    );

    if (account.isDemo) {
      return (
        <LoadingButton
          type="primary"
          onClick={() => {
            window.location = APP_REGISTER_URL;
          }}
        >
          Sign Up
        </LoadingButton>
      );
    }

    if (account.user.isBeingImpersonated) {
      return (
        <LoadingButton
          type="danger"
          onClick={() => {
            account.logOut({
              email: account.user.impersonatorUserEmail,
            });
          }}
        >
          Exit
        </LoadingButton>
      );
    }

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={classNames(clsPrefix, className)}>
          {!ui.isMobileSize && showThemeLogo && (
            <ThemeLogo
              className={`${clsPrefix}__theme-logo`}
              url={get(ui, 'theme.logoUrl')}
            />
          )}
          <UserAvatar user={account.user} tooltip={null} />
        </div>
      </Dropdown>
    );
  })
);

AppDropdownAvatar.defaultProps = {
  showThemeLogo: true,
};

export default AppDropdownAvatar;
