

import PropTypes from 'prop-types';
import React from 'react';

const clsPrefix = 'app-theme-logo';

const ThemeLogo = ({ className, url }) =>
  url ? (
    <div
      className={`${clsPrefix} ${className || ''}`.trim()}
      style={{
        backgroundImage: `url(${url})`,
      }}
    />
  ) : null;

ThemeLogo.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
};
export default ThemeLogo;
