import AppButton from 'src/components/common/app-button';
import React from 'react';
import loadingWrap from './loading-wrap';

export default loadingWrap(
  ({ onClick, loading, handleAsync, children, ...props }) => {
    const body = children;

    const wrappedOnClick = loading ? () => {} : handleAsync(onClick);
    return (
      <AppButton {...props} loading={loading} onClick={wrappedOnClick}>
        {body}
      </AppButton>
    );
  }
);
