

import React, { Component } from 'react';
import { Badge, Dropdown, Popover, Tooltip } from 'antd';
import classNames from 'classnames';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppIcon from 'src/components/common/app-icon';
import bindLoading from 'src/components/common/bind-loading';
import navigateClick from 'src/utils/navigate-click';
import preventDefault from 'src/utils/prevent-default';
import AppDropdownAvatar from './app-dropdown-avatar';

const clsPrefix = 'app-top-bar-buttons';

export const DEFAULT_SIZE = 24;

function DropdownAvatar(props) {
  return <AppDropdownAvatar className={`${clsPrefix}__avatar`} {...props} />;
}

@bindLoading({
  loading: 'onClick',
})
class IconButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.any,
    svg: PropTypes.any,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    title: PropTypes.string,
    badge: PropTypes.number,
    active: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
  };

  render() {
    const {
      className,
      icon,
      loading,
      title,
      onClick,
      badge = 0,
      active,
      svg,
    } = this.props;

    const iconProps = isPlainObject(icon) ? icon : null;
    const iconName = isString(icon) ? icon : null;

    return (
      <div
        className={classNames(`${clsPrefix}__icon-button`, className, {
          [`${clsPrefix}__icon-button--has-badge`]: !!badge,
          [`${clsPrefix}__icon-button--active`]: !!active,
        })}
      >
        <Tooltip placement="bottom" title={title} hideAction={['click']}>
          <Badge count={badge} offset={[-2, 4]}>
            <a
              href="#"
              className={`${clsPrefix}__icon-button-link`}
              onClick={preventDefault(onClick)}
              title={title}
            >
              <AppIcon
                type={svg ? 'svg' : 'feather'}
                size={DEFAULT_SIZE}
                name={iconName}
                svg={svg}
                {...iconProps}
                {...(loading
                  ? {
                      type: 'antd',
                      name: 'loading',
                    }
                  : null)}
              />
            </a>
          </Badge>
        </Tooltip>
      </div>
    );
  }
}

class PopoverIcon extends Component {
  state = {
    popoverVisible: false,
  };

  handleVisibleChange = (popoverVisible) => {
    const { onVisibleChange } = this.props;

    this.setState({
      popoverVisible,
    });

    if (onVisibleChange) {
      onVisibleChange(popoverVisible);
    }
  };

  render() {
    const {
      className,
      overlayClassName,
      content,
      title,
      ...otherProps
    } = this.props;

    return (
      <Popover
        overlayClassName={classNames(`${clsPrefix}__popover`, overlayClassName)}
        trigger={['click']}
        content={content}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomRight"
      >
        <IconButton
          className={classNames(className, {
            [`${clsPrefix}__icon-button--popover-visible`]: this.state
              .popoverVisible,
          })}
          title={this.state.popoverVisible ? null : title}
          {...otherProps}
        />
      </Popover>
    );
  }
}

PopoverIcon.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  content: PropTypes.any,
  title: PropTypes.string,
  onVisibleChange: PropTypes.func,
};

class DropdownIcon extends Component {
  state = {
    popoverVisible: false,
  };

  handleVisibleChange = (popoverVisible) => {
    const { onVisibleChange } = this.props;

    this.setState({
      popoverVisible,
    });

    if (onVisibleChange) {
      onVisibleChange(popoverVisible);
    }
  };

  render() {
    const { className, overlay, title, ...otherProps } = this.props;

    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <div
            onClick={() => {
              // HACK: For some reason onVisibleChange doesn't fire
              // when clicking on an element in the overlay. This simulates
              // the correct behavior.
              this.handleVisibleChange(false);
            }}
            role="menu"
          >
            {overlay}
          </div>
        }
        onVisibleChange={this.handleVisibleChange}
        placement="bottomRight"
      >
        <IconButton
          className={classNames(className, {
            [`${clsPrefix}__icon-button--popover-visible`]: this.state
              .popoverVisible,
          })}
          title={this.state.popoverVisible ? null : title}
          {...otherProps}
        />
      </Dropdown>
    );
  }
}

DropdownIcon.propTypes = {
  className: PropTypes.string,
  overlay: PropTypes.any,
  title: PropTypes.string,
  onVisibleChange: PropTypes.func,
};

function Divider() {
  return <div className={`${clsPrefix}__divider`} />;
}

function Button({ className, linkClassName, onClick, children }) {
  return (
    <div className={classNames(`${clsPrefix}__button`, className)}>
      <a
        href="#"
        onClick={preventDefault(onClick)}
        className={classNames(`${clsPrefix}__button-button`, linkClassName)}
      >
        {children}
      </a>
    </div>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

function TextButton({ className, onClick, children, active, badge }) {
  return (
    <div className={classNames(`${clsPrefix}__button`, className)}>
      <a
        href="#"
        onClick={preventDefault(onClick)}
        className={classNames(`${clsPrefix}__button-text-button`, {
          [`${clsPrefix}__button-text-button--active`]: active,
          [`${clsPrefix}__button-text-button--badge`]: !!badge,
        })}
      >
        {children}
        {!!badge && <Badge className={`${clsPrefix}__badge`} count={badge} />}
      </a>
    </div>
  );
}

TextButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  active: PropTypes.bool,
  badge: PropTypes.number,
};

const LinkButton = inject('router')(
  observer(({ router, routeName, routeParams, ...props }) => (
    <TextButton
      {...props}
      onClick={(e) => navigateClick(e, router, routeName, routeParams)}
    />
  ))
);

function TopBarButtons({
  className,
  light,
  children,
  ui,
  left = false,
  withDivider = false,
}) {
  const sideCls = left ? `${clsPrefix}--left` : `${clsPrefix}--right`;
  const showDivider = children && withDivider && !ui.isMobileSize;
  return (
    <div
      className={classNames(clsPrefix, sideCls, className, {
        [`${clsPrefix}--light`]: light,
      })}
    >
      {showDivider && !left && <Divider />}
      {children}
      {showDivider && left && <Divider />}
    </div>
  );
}

TopBarButtons.propTypes = {
  className: PropTypes.string,
  light: PropTypes.bool,
  children: PropTypes.any,
  left: PropTypes.bool,
  withDivider: PropTypes.bool,
  ui: PropTypes.object.isRequired,
};

TopBarButtons.DropdownAvatar = DropdownAvatar;

TopBarButtons.PopoverIcon = PopoverIcon;

TopBarButtons.DropdownIcon = DropdownIcon;

TopBarButtons.IconButton = IconButton;

TopBarButtons.Divider = Divider;

TopBarButtons.Button = Button;

TopBarButtons.TextButton = TextButton;

TopBarButtons.LinkButton = LinkButton;

export default inject('ui')(TopBarButtons);
